<template>
    <div class="access_home-content">

        <div class="access_toolbar">
            <div class="access_toolbar_item">
                <div class="access_toolbar_item_icon" @click="toDeviceView">
                    <i class="el-icon-monitor" style="font-size: 50px;color: white"></i>
                </div>
                <div class="access_toolbar_item_content" @click="toDeviceView">
                    <div style="font-size: 25px">设备数量</div>
                    <div style="margin-top: 20px">{{showTitle(1)}}</div>
                </div>
            </div>
            <div class="access_toolbar_item1">
                <div class="access_toolbar_item_icon1" @click="toPersonView">
                    <i class="el-icon-user-solid" style="font-size: 50px;color: white"></i>
                </div>
                <div class="access_toolbar_item_content" @click="toPersonView">
                    <div style="font-size: 25px">人员数量</div>
                    <div style="margin-top: 20px">{{showTitle(2)}}</div>
                </div>
            </div>
            <div class="access_toolbar_item2">
                <div class="access_toolbar_item_icon2" @click="getDeviceRecordCount(2)">
                    <i class="el-icon-box" style="font-size: 50px;color: white"></i>
                </div>
                <div class="access_toolbar_item_content" @click="getDeviceRecordCount(2)">
                    <div style="font-size: 25px">记录数量</div>
                    <div style="margin-top: 20px">{{showTitle(3)}}</div>
                </div>
            </div>
            <div class="access_toolbar_item3">
                <div class="access_toolbar_item_icon3" @click="getDeviceRecordCount(1)">
                    <i class="el-icon-s-custom" style="font-size: 50px;color: white"></i>
                </div>
                <div class="access_toolbar_item_content" @click="getDeviceRecordCount(1)">
                    <div style="font-size: 25px">今日刷脸</div>
                    <div style="margin-top: 20px">{{showTitle(4)}}</div>
                </div>
            </div>

            <div class="access_toolbar_item4">
                <div class="access_toolbar_item_icon4" @click="getDeviceRecordCount(3)">
                    <i class="el-icon-loading" style="font-size: 50px;color: white"></i>
                </div>
                <div class="access_toolbar_item_content" @click="getDeviceRecordCount(3)">
                    <div style="font-size: 25px">今日访客</div>
                    <div style="margin-top: 20px">{{showTitle(5)}}</div>
                </div>
            </div>
        </div>

        <div class="access_data-content">
            <div class="access_record">
                <el-table :data="recordData" height="100%" :row-class-name="tableRowClassName"
                          v-loading="loading" filters="">
                    <el-table-column align="center" prop="fileUrl" label="抓拍照片" width="80">
                        <template scope="scope">
                            <el-image style="width: 50px;height: 50px;" :src="scope.row.fileUrl"
                                      @click="showImage(scope.row.fileUrl)"/>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="personFileUrl" label="底图照片" width="80">
                        <template scope="scope">
                            <el-image style="width: 50px;height: 50px;" :src="scope.row.personFileUrl"
                                      @click="showImage(scope.row.personFileUrl)">
                                <div slot="error" class="image-slot"
                                     style="display: flex;flex-direction: column;justify-content: center;align-items: center;width: 100%;height: 100%">
                                    <img style="width: 40px;height: 40px;" src="../../../assets/error.png"/>
                                </div>
                            </el-image>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="name" label="姓名" width="150">
                    </el-table-column>
                    <el-table-column align="center" prop="success" label="结果" width="100">
                        <template slot-scope="scope">
                            <el-tag :type="scope.row.success?'success':'danger'" disable-transitions>
                                {{scope.row.success?'成功':'失败'}}
                            </el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="temperature" label="体温" width="100">
                    </el-table-column>
                    <el-table-column align="center" prop="" label="其它" width="100">
                        <template slot-scope="scope">
                            <el-popover trigger="hover" placement="top">
                                <p>身份证: {{ scope.row.idCard }}</p>
                                <p>IC卡: {{ scope.row.icCard }}</p>
                                <p>验证类型: {{getVerificationtype(scope.row.verificationType)}}</p>
                                <p>分数: {{ scope.row.score }}</p>
                                <p>设备名称: {{ scope.row.accessDevice.name }}</p>
                                <div slot="reference" class="name-wrapper">
                                    <el-tag size="medium">查看</el-tag>
                                </div>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="date" label="日期" width="200">
                    </el-table-column>
                </el-table>
            </div>
            <div class="access_states">
                <div id="myChart" style="width: 100%;height: 100%"></div>
            </div>

        </div>
        <el-dialog title="查看照片" :visible.sync="showImageDialogVisible" width="20%" :before-close="handleClose"
                   :modal-append-to-body="false">
			<span>
				<el-image :src="dialogPersonFileUrl"></el-image>
			</span>
            <span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="showImageDialogVisible = false">确 定</el-button>
			</span>
        </el-dialog>

        <el-dialog :title="recordCountTitle" :visible.sync="showAllDeviceRecordCountDialogVisible" width="30%"
                   :before-close="handleClose"
                   :modal-append-to-body="false">
            <span style="display: flex;flex-direction: column;width: 100%;height: 500px">
                <el-table ref="filterTable" :data="allDeviceRecordCountData" height="100%"
                          v-loading="allDeviceRecordCountDataLoading">
                    <el-table-column
                            label="序号"
                            align="center"
                            type="index"
                            width="100">
                </el-table-column>
                <el-table-column align="center" prop="name" label="设备名称" width="auto">
                </el-table-column>
                <el-table-column align="center" prop="recordCount" label="刷脸次数" width="auto">
                    <template slot-scope="scope">
                        <el-tag type="success" disable-transitions>
                            {{scope.row.recordCount}}
                        </el-tag>
                    </template>
                </el-table-column>

            </el-table>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                recordData: [],
                recordCount: 0,
                deviceCount: 0,
                personCount: 0,
                todayCount: 0,
                visitorCount: 0,
                loading: false,
                title: [],
                showImageDialogVisible: false,
                page: 1,
                length: 20,
                dialogPersonFileUrl: '',
                gg: ['gg', 'aa'],
                showAllDeviceRecordCountDialogVisible: false,
                allDeviceRecordCountData: [],
                allDeviceRecordCountDataLoading: false,
                recordCountTitle: '记录数量'
            }
        },
        mounted() {
            this.findAWeekState()
            this.findPersonCount()
            this.findTodayCount()
            this.findDeviceCount()
            this.findVisitorCount()
            this.loadData()
            setInterval(() => {
                var path = this.$route.path
                if (path === '/access_home') {
                    this.getRecordCount()
                } else {
                    clearInterval(this)
                }
            }, 5000)
        },
        methods: {
            handleClose(done) {
                this.dialogPersonFileUrl = ''
                done()
            },
            errorHandler() {
                return true
            },
            //绘制表格
            tableRowClassName({row, rowIndex}) {

            },
            //绘制统计图
            drawLine(data) {
                // 基于准备好的dom，初始化echarts实例
                let myChart = this.$echarts.init(document.getElementById('myChart'))
                // 绘制图表
                myChart.setOption({
                    title: {
                        text: '',
                    },
                    tooltip: {},
                    xAxis: {
                        data: [
                            '星期一',
                            '星期二',
                            '星期三',
                            '星期四',
                            '星期五',
                            '星期六',
                            '星期天',
                        ],
                    },
                    yAxis: {},
                    series: [{
                        name: '刷脸',
                        type: 'bar',
                        data: data,
                    },],
                })
            },
            getRecordCount() {
                setTimeout(() => {
                    axios.get('/accessRecord/count')
                        .then((res) => {
                            if (res.data.code === 1) {
                                if (this.recordCount !== res.data.data.count) {
                                    this.loadData()
                                    this.findAWeekState()
                                    this.findPersonCount()
                                    this.findTodayCount()
                                    this.findDeviceCount()
                                    this.findVisitorCount()
                                }
                            }
                        })
                })
            },
            //加载数据
            loadData() {
                this.loading = true
                setTimeout(() => {
                    axios.post('/accessRecord/pagingQuery', {
                        page: this.page,
                        length: this.length,

                    }).then((res) => {
                        this.loading = false
                        if (res.data.code === 1) {
                            this.recordCount = res.data.data.totalElements
                            this.recordData = res.data.data.content
                        }
                    }).catch((error) => {
                        this.loading = false
                    })
                })
            },
            // 获取当前星期刷脸统计
            findAWeekState() {
                setTimeout(() => {
                    axios.get('/accessRecord/findAWeekState').then((res) => {
                        if (res.data.code === 1) {
                            this.drawLine(res.data.data)
                        }
                    })
                })
            },
            //设备数量
            findDeviceCount() {
                setTimeout(() => {
                    axios.get('/accessDevice/count').then((res) => {
                        this.deviceCount = res.data.data.count
                    })
                })
            },
            //人员数量
            findPersonCount() {
                setTimeout(() => {
                    axios.get('/accessPerson/count').then((res) => {
                        this.personCount = res.data.data.count
                    })
                })
            },
            //今日刷脸记录总数
            findTodayCount() {
                setTimeout(() => {
                    axios.get('/accessRecord/findTodayCount').then((res) => {
                        this.todayCount = res.data.data.count
                    })
                })
            },
            //今日访客记录总数
            findVisitorCount() {
                setTimeout(() => {
                    axios.post('/accessRecord/findVisitorTodayCount', {
                        parameter: {

                        }
                    }).then((res) => {
                        this.visitorCount = res.data.data.count
                    })
                })
            },
            showTitle(index) {
                if (index === 1) {
                    return this.deviceCount
                }
                if (index === 2) {
                    return this.personCount
                }
                if (index === 3) {
                    return this.recordCount
                }
                if (index === 4) {
                    return this.todayCount
                }
                if (index === 5) {
                    return this.visitorCount
                }
                return ''
            },
            showImage(fileUrl) {
                this.dialogPersonFileUrl = fileUrl
                this.showImageDialogVisible = true
            },
            getVerificationtype(type) {
                if (type === 0) {
                    return '1:N'
                } else if (type === 1) {
                    return '1:1'
                } else if (type === 2) {
                    return '1:N+T'
                } else if (type === 3) {
                    return '1:1+T'
                } else if (type === 4) {
                    return '仅测温'
                } else if (type === 5) {
                    return '单刷卡'
                } else if (type === 6) {
                    return '单刷卡测温'
                } else if (type === 7) {
                    return '临时访客二维码'
                } else if (type === 8) {
                    return '密码开门'
                } else if (type === 9) {
                    return '远程开门'
                } else if (type === 10) {
                    return '健康码'
                } else {
                    return '1:N'
                }
            },
            toDeviceView() {
                this.$router.push('access_device')
            },
            toPersonView() {
                this.$router.push('access_person')
            },
            toRecordView() {
                this.$router.push('access_record')
            },

            getDeviceRecordCount(type) {
                this.showAllDeviceRecordCountDialogVisible = true
                this.allDeviceRecordCountDataLoading = true
                setTimeout(() => {
                    if (type === 1) {
                        this.recordCountTitle = '今日刷脸'
                        axios.post('/accessDevice/findAllToDayDeviceRecordCount')
                            .then((res) => {
                                this.allDeviceRecordCountDataLoading = false
                                if (res.data.code === 1) {
                                    this.allDeviceRecordCountData = res.data.data
                                }
                            })
                            .catch((error) => {
                                this.allDeviceRecordCountDataLoading = false
                            })
                    } else if (type === 2) {
                        this.recordCountTitle = '记录数量'
                        axios.post('/accessDevice/findAllDeviceRecordCount')
                            .then((res) => {
                                this.allDeviceRecordCountDataLoading = false
                                if (res.data.code === 1) {
                                    this.allDeviceRecordCountData = res.data.data
                                }
                            })
                            .catch((error) => {
                                this.allDeviceRecordCountDataLoading = false
                            })
                    } else {
                        this.recordCountTitle = '今日访客'
                        axios.post('/accessDevice/findAllToDayVisitorDeviceRecordCount')
                            .then((res) => {
                                this.allDeviceRecordCountDataLoading = false
                                if (res.data.code === 1) {
                                    this.allDeviceRecordCountData = res.data.data
                                }
                            })
                            .catch((error) => {
                                this.allDeviceRecordCountDataLoading = false
                            })
                    }

                })
            }
        },
    }
</script>

<style>
    .access_home-content {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        margin-bottom: 20px;
    }

    .access_toolbar {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 95%;
        height: 120px;
        margin-bottom: 20px;
        margin-top: 10px;
    }

    .access_toolbar_item {
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 100%;
        flex: 1;
        border-radius: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    }

    .access_toolbar_item1 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 100%;
        flex: 1;
        margin-left: 20px;
        border-radius: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    }

    .access_toolbar_item2 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 100%;
        flex: 1;
        margin-left: 20px;
        border-radius: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    }

    .access_toolbar_item3 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 100%;
        flex: 1;
        margin-left: 20px;
        border-radius: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    }

    .access_toolbar_item4 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 100%;
        flex: 1;
        margin-left: 20px;
        border-radius: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    }

    .access_toolbar_item_icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 40%;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        background: #9FB95A;
    }

    .access_toolbar_item_icon1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 40%;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        background: #3a8ee6;
    }

    .access_toolbar_item_icon2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 40%;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        background: #64D572;
    }

    .access_toolbar_item_icon3 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 40%;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        background: #F25E43;
    }

    .access_toolbar_item_icon4 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 40%;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        background: #42b983;
    }

    .access_toolbar_item_content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 70%;
        font-size: 20px;
    }


    .access_data-content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 95%;
        flex: 1;
    }

    .access_record {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
        margin-right: 10px;
        height: 100%;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    }

    .access_states {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        flex: 1;
        height: 100%;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    }

</style>
